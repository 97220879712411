import './style.css';

const Introduction = () => (
    <div id="about">
        <h1 className="header">About Me</h1>

        <div className="css-typing">
            <p>My name is Oliver.</p>
            <p>I am a Software Engineer.</p>
        </div>

        <p>
            I am a final year Computer Science and Business undergraduate from Nanyang Technlogical
            University in Singapore. I love exploring new technologies and I am super passionate
            about using technology to solve problems that businesses and common folks face.
        </p>

        <p>
            I am constantly on the lookout for opportunities to collaborate as well as problems to
            solve. Drop me an email if you are interested! &#128526;
        </p>
    </div>
);

export default Introduction;
