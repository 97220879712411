import './App.css';
import Body from './components/Body';
import Sider from './components/Sider';

const App = () => (
    <div id="main-container">
        <Sider />
        <Body />
    </div>
);

export default App;
