import './style.css';

export type AwardEntryProps = {
    awardName: string;
    date: string;
    details: string[];
};

const AwardEntry = ({ awardName, date, details }: AwardEntryProps) => (
    <div className="card">
        <div className="card-title">
            <h3>{awardName}</h3>
        </div>

        <div className="card-date">
            <span>{date}</span>
        </div>

        <ul className="card-content">
            {details.map((detail) => (
                <li key={detail}>{detail}</li>
            ))}
        </ul>
    </div>
);

export default AwardEntry;
