import githubLogo from '../../../../assets/github_logo.svg';
import './style.css';
import linkLogo from '../../../../assets/link_logo.svg';

export type ProjectEntryProps = {
    date?: string;
    details: string[];
    githubId: string;
    githubUrl: string;
    images: string[];
    logos: (string | undefined)[];
    projectName: string;
    techs: string;
    url?: string;
};

const ProjectEntry = ({
    projectName,
    githubId,
    githubUrl,
    url = '',
    logos,
    techs,
    date,
    details,
    images,
}: ProjectEntryProps) => (
    <div className="card">
        <div className="card-title">
            <h3>{projectName}</h3>
            {logos.map((logo) => (
                <img key={logo} alt="logo" className="logo" src={logo} />
            ))}

            <div className="sm-logos">
                {githubUrl && (
                    <a href={githubUrl} rel="noreferrer" target="_blank">
                        <img alt="gh" className="sm-logo" src={githubLogo} />
                    </a>
                )}
                {url && (
                    <a href={url} rel="noreferrer" target="_blank">
                        <img alt="li" className="sm-logo" src={linkLogo} />
                    </a>
                )}
            </div>
        </div>

        <div className="card-sub-title">
            <p>Technology Stack: {techs}</p>
            <p>{date}</p>
        </div>

        <ul className="card-content">
            {details.map((detail) => (
                <li key={detail}>{detail}</li>
            ))}
        </ul>

        <div className="carousel slide" data-bs-ride="carousel" id={`${githubId}`}>
            <ol className="carousel-indicators">
                {images.map((img, index) => (
                    <li
                        key={img}
                        className={index === 0 ? 'active' : ''}
                        data-bs-slide-to={index}
                        data-bs-target={`#${projectName}`}
                    />
                ))}
            </ol>
            <div className="carousel-inner">
                {images.map((img, index) => (
                    <div key={img} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        <img alt="demo" className="d-block w-100" src={img} />
                    </div>
                ))}
            </div>
            <a
                className="carousel-control-prev"
                data-bs-slide="prev"
                href={`#${githubId}`}
                role="button"
            >
                <span aria-hidden="true" className="carousel-control-prev-icon" />
                <span className="visually-hidden">Previous</span>
            </a>
            <a
                className="carousel-control-next"
                data-bs-slide="next"
                href={`#${githubId}`}
                role="button"
            >
                <span aria-hidden="true" className="carousel-control-next-icon" />
                <span className="visually-hidden">Next</span>
            </a>
        </div>
    </div>
);

export default ProjectEntry;
