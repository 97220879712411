import './style.css';

const Skills = () => (
    <div id="skills">
        <h1 className="header">Skills</h1>

        <h5>
            Here is a list of technologies that I have worked with, categorized in proficiency
            levels:
        </h5>

        <div className="skills-categories">
            <div className="proficient-skills card">
                <h6>Proficient</h6>
                <ul>
                    <li>JavaScript</li>
                    <li>React</li>
                    <li>Python</li>
                    <li>Docker</li>
                    <li>Kubernetes</li>
                </ul>
            </div>
            <div className="intermediate-skills card">
                <h6>Intermediate</h6>
                <ul>
                    <li>Golang</li>
                    <li>TypeScript</li>
                    <li>Node.js</li>
                    <li>Express.js</li>
                    <li>MongoDB</li>
                    <li>Java</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>Git</li>
                </ul>
            </div>
            <div className="beginner-skills card">
                <h6>Beginner</h6>
                <ul>
                    <li>Angular</li>
                    <li>VueJS</li>
                    <li>SQL</li>
                    <li>Jest</li>
                    <li>Docker</li>
                    <li>C / C++</li>
                    <li>Quarkus</li>
                    <li>scikit-learn</li>
                </ul>
            </div>
        </div>

        <p>
            I enjoy learning new technologies! If there is a technology not listed here, I might
            have used it in a pet project before.
        </p>
    </div>
);

export default Skills;
