export type EducationEntryProps = {
    certName: string;
    date: string;
    details: string[];
    logos: (string | undefined)[];
    schoolName: string;
};

const EducationEntry = ({ schoolName, logos, certName, date, details }: EducationEntryProps) => (
    <div className="card">
        <div className="card-title">
            <h3>{schoolName}</h3>
            {logos.map((logo) => (
                <img key={logo} alt="logo" className="logo" src={logo} />
            ))}
        </div>

        <div className="card-sub-title">
            <p>{certName}</p>
            <p>{date}</p>
        </div>

        <ul className="card-content">
            {details.map((detail) => (
                <li key={detail}>{detail}</li>
            ))}
        </ul>
    </div>
);

export default EducationEntry;
