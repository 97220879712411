import './style.css';
import React, { useEffect } from 'react';
import Awards from '../sections/Awards';
import Education from '../sections/Education';
import Introduction from '../sections/Introduction';
import Projects from '../sections/Projects';
import Skills from '../sections/Skills';
import Work from '../sections/Work';
import menuLogo from '../../assets/menu_logo.svg';

const Body = () => {
    useEffect(() => {
        const tabs = ['i-link', 'e-link', 'w-link', 'p-link', 's-link', 'a-link'];

        const setActiveTab = (newTab: string) => {
            tabs.forEach((tab) => {
                document.getElementById(tab)!.className = 'scroll-inactive';
            });

            document.getElementById(newTab)!.className = 'scroll-active';
        };

        // setActiveTab("i-link");

        const checkScroll = () => {
            const aboutSection: HTMLElement | null = document.getElementById('about');
            const educationSection: HTMLElement | null = document.getElementById('education');
            const workSection: HTMLElement | null = document.getElementById('work');
            const projectSection: HTMLElement | null = document.getElementById('projects');
            const skillSection: HTMLElement | null = document.getElementById('skills');

            if (
                aboutSection &&
                window.scrollY <= aboutSection.offsetTop + aboutSection.offsetHeight
            ) {
                setActiveTab('i-link');
            }

            if (
                aboutSection &&
                window.scrollY >= aboutSection.offsetTop + aboutSection.offsetHeight - 1
            ) {
                setActiveTab('e-link');
            }

            if (
                educationSection &&
                window.scrollY >= educationSection.offsetTop + educationSection.offsetHeight - 1
            ) {
                setActiveTab('w-link');
            }

            if (
                workSection &&
                window.scrollY >= workSection.offsetTop + workSection.offsetHeight - 1
            ) {
                setActiveTab('p-link');
            }

            if (
                projectSection &&
                window.scrollY >= projectSection.offsetTop + projectSection.offsetHeight - 1
            ) {
                setActiveTab('s-link');
            }

            if (
                skillSection &&
                window.scrollY >= skillSection.offsetTop + skillSection.offsetHeight - 1
            ) {
                setActiveTab('a-link');
            }
        };

        window.addEventListener('scroll', checkScroll);

        return () => window.removeEventListener('scroll', checkScroll);
    }, []);

    const renderSider = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (window.innerWidth < 840) {
            const siderElement: HTMLElement | null = document.getElementById('sider');
            if (siderElement) {
                siderElement.style.display = 'flex';
                e.stopPropagation();
            }
        }
    };

    const closeSider = () => {
        if (window.innerWidth < 840) {
            const siderElement: HTMLElement | null = document.getElementById('sider');
            if (siderElement) {
                siderElement.style.display = 'none';
            }
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div id="body" onClick={closeSider}>
            <div
                id="sider-toggle"
                onClick={renderSider}
                onKeyPress={renderSider}
                role="button"
                tabIndex={0}
            >
                <img alt="menu" src={menuLogo} />
            </div>
            <Introduction />
            <Education />
            <Work />
            <Projects />
            <Skills />
            <Awards />
        </div>
    );
};

export default Body;
