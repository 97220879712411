import './style.css';
import WorkEntry from './WorkEntry';
import appvLogo from '../../../assets/appv_logo.png';
import dbsLogo from '../../../assets/dbs_logo.jpg';
import mtnLogo from '../../../assets/mtn_logo.jpeg';
import nhbLogo from '../../../assets/nhb_logo.png';
import ntuLogo from '../../../assets/ntu_logo.png';
import seagateLogo from '../../../assets/seag_logo.jpg';
import zenLogo from '../../../assets/zendesk_logo.png';

const WorkSection = () => (
    <div id="work">
        <h1 className="header">Work Experience</h1>

        <WorkEntry
            companyName="Zendesk"
            date="Aug 2022 - Present"
            details={[
                'Part of the Engineering Productivity team, I write software to develop solutions for the testing infrastructure at Zendesk.',
            ]}
            jobRole="Software Engineer"
            logos={[zenLogo]}
        />

        <WorkEntry
            companyName="Motional"
            date="May 2022 - Aug 2022"
            details={[
                'Worked on an existing 3D visualization frontend application and shipped out production-ready features.',
                'Involved in optimizing the backend infrastructure with AWS resources and enabling a production-level setup with monitoring.',
            ]}
            jobRole="Software Engineer Intern"
            logos={[mtnLogo]}
        />

        <WorkEntry
            companyName="Zendesk"
            date="Aug 2021 - Jan 2022"
            details={[
                "Contributed to helping build and raise the quality of the testing infrastructure at Zendesk to improve reliability of Zendesk's suite of products.",
                'Worked with micro-services and service mesh with Golang and Kubernetes, pushing features and bug fixes related to both functional and non-functional requirements.',
            ]}
            jobRole="Software Engineer Intern"
            logos={[zenLogo]}
        />

        <WorkEntry
            companyName="Seagate Technology"
            date="May 2021 - Aug 2021"
            details={[
                'Contributed as a full-stack web application developer and provided inputs on product features as well as technology tools.',
                'Migrated live features to a new portal and worked on enhancing current projects, using React Hooks, TypeScript and Node.js.',
            ]}
            jobRole="Software Engineer Intern"
            logos={[seagateLogo]}
        />

        <WorkEntry
            companyName="Appvantage"
            date="Mar 2021 - May 2021"
            details={[
                'Worked directly on one of the company’s leading automotive digital solutions in the full-stack software development department, which serves dealerships and large automotive OEMs across the world like Porsche.',
                "Tasked with pushing preview features and fixing production bugs for the company's growing web application, using technology tools like React Hooks, TypeScript, Apollo GraphQL, NodeJS, CI/CD pipeline.",
            ]}
            jobRole="Software Engineer Intern"
            logos={[appvLogo]}
        />

        <WorkEntry
            companyName="National Heritage Board & Nanyang Technological University"
            date="Dec 2020 - Mar 2021"
            details={[
                'Worked on the Hidden Shrines project, a digital humanities research project that archives and maps small but important shrines hidden in the rapidly changing urban and jungle environments of Singapore and Sri Lanka.',
                'Collaborated closely with stakeholders comprising of researchers, designers and associate professors regarding the application development and contributed ideas on UI/UX design.',
                'Revamped the user interface of the Django web application, fixed existing bugs and made enhancements to the database design.',
            ]}
            disclaimer="This project is funded by the National Heritage Board of Singapore, and is the product of a collaboration between the School of Art, Design and Media (ADM), the School of Computer Science and Engineering (SCSE), and NTU Instiutute for Science and Technology for Humanity (NISTH) at Nanyang Technological University."
            jobRole="Software Engineer"
            logos={[nhbLogo, ntuLogo]}
        />

        <WorkEntry
            companyName="DBS Bank"
            date="Jun 2020 - Aug 2020"
            details={[
                'Engineered a full-stack web application designed to automate workflows within the Investment and Trading Technology Team under the Technology and Operations department.',
                "Contributed at bi-monthly meetings with senior management for status updates on development tasks, sprint review and planning, closely adopting DBS's agile practices in software engineering.",
                'Documented software design which includes user interface prototypes, architecture design, API specifications and database schema design.',
                'Integrated the web application into an existing internal web portal.',
                'Carried out unit testing and integration testing and achieved 80% test coverage.',
                'Used React on the frontend and Quarkus on the backend to develop the web application to be deployed into production.',
                'Delivered the software project within a challenging timeline.',
            ]}
            jobRole="Software Engineer Intern"
            logos={[dbsLogo]}
        />
    </div>
);

export default WorkSection;
