import './style.css';
import ProjectEntry from './ProjectEntry';
import hotelBookOne from '../../../assets/hotelBook1.jpg';
import hotelBookTwo from '../../../assets/hotelBook2.jpg';
import hotelBookThree from '../../../assets/hotelBook3.jpg';
import hotelBookLogo from '../../../assets/hotelbook_logo.png';
import labeldatOne from '../../../assets/labeldat1.png';
import labeldatTwo from '../../../assets/labeldat2.png';
import labeldatThree from '../../../assets/labeldat3.png';
import labeldatFour from '../../../assets/labeldat4.png';
import labeldatLogo from '../../../assets/labeldat_logo.png';
import natureBibleOne from '../../../assets/naturebible1.jpg';
import natureBibleTwo from '../../../assets/naturebible2.jpg';
import natureBibleLogo from '../../../assets/naturebible_logo.png';
import parkingSlotOne from '../../../assets/parkingslot1.png';
import parkingSlotTwo from '../../../assets/parkingslot2.png';
import parkingSlotThree from '../../../assets/parkingslot3.png';
import parkingSlotFour from '../../../assets/parkingslot4.png';
import parkingSlotLogo from '../../../assets/parkingslot_logo.png';
import stockeerOne from '../../../assets/stockeer1.png';
import stockeerTwo from '../../../assets/stockeer2.png';
import stockeerThree from '../../../assets/stockeer3.png';
import stockeerFour from '../../../assets/stockeer4.png';
import stockeerLogo from '../../../assets/stockeer_logo.png';
import urlShortOne from '../../../assets/urlShort1.png';
import urlShortLogo from '../../../assets/urlShort_logo.png';

const ProjectSection = () => (
    <div id="projects">
        <h1 className="header">Projects</h1>

        <ProjectEntry
            details={[
                'Developing a native mobile application for all nature lovers to get information on the whereabouts of nature in Singapore.',
            ]}
            githubId="nature-bible"
            githubUrl="//gitfront.io/r/user-8711426/94754b484d9efab1b24def3497fd876108718a9e/nature-bible/"
            images={[natureBibleOne, natureBibleTwo]}
            logos={[natureBibleLogo]}
            projectName="NatureBible"
            techs="React Native, TypeScript, Node.js, Apollo GraphQL, MongoDB"
        />

        <ProjectEntry
            details={['Developed an easy-to-use URL shortening service.']}
            githubId="url-shortener"
            githubUrl="//github.com/jlgoh/url-shortener"
            images={[urlShortOne]}
            logos={[urlShortLogo]}
            projectName="URL Shortener"
            techs="React, TypeScript, Node.js, PostgreSQL"
            url="https://olivers-short.herokuapp.com"
        />

        <ProjectEntry
            details={[
                'Developed a native cross-platform mobile application prototype for users to get alerts for the cheapest hotel rates.',
                'Hotelbook is an application that helps users who are unsure whether the prices of the hotels they wish to book will drop further.',
                'It provides an intuitive interface where users can simply add listings to their watchlists by entering the URLs of their desired listings.',
                'Through web-scraping, Hotelbook will retrieve and compare the latest prices from multiple booking sites and send push notifications to users when prices fall below their target price.',
            ]}
            githubId="hotelbook"
            githubUrl="//gitfront.io/r/user-8711426/a81ca757f57788b9f7ef58749b6f764f53c984fe/hotelbook/"
            images={[hotelBookOne, hotelBookTwo, hotelBookThree]}
            logos={[hotelBookLogo]}
            projectName="Hotelbook"
            techs="React Native, Node.js, Express.js, MongoDB"
        />

        <ProjectEntry
            details={[
                'Developed an application to provide a streamlined platform for labelling data for users in the world of machine learning.',
                'LabelDat helps in labelling datasets of various forms such as image, audio, video, and text.',
                'LabelDat also provides an intuitive interface for users to carry out labelling and helps to keep track of progress through comprehensive dashboards.',
            ]}
            githubId="labeldat"
            githubUrl="//github.com/jlgoh/labeldat"
            images={[labeldatOne, labeldatTwo, labeldatThree, labeldatFour]}
            logos={[labeldatLogo]}
            projectName="LabelDat"
            techs="React, Flask, MySQL"
            url="//labeldat.herokuapp.com"
        />

        <ProjectEntry
            details={[
                'Built a web application to provide accurate real-time stock market data, visualized in Candlestick charts.',
                'Stockeer is a minimalistic application that allows users to search for stock market data, view these data in easy-to-understand Candlestick charts and create a watchlist for stocks to monitor.',
                'Stockeer provides user-friendly features like Google OAuth Sign-In and search suggestions.',
            ]}
            githubId="stockeer"
            githubUrl="//github.com/jlgoh/stockeer"
            images={[stockeerOne, stockeerTwo, stockeerThree, stockeerFour]}
            logos={[stockeerLogo]}
            projectName="Stockeer"
            techs="React, Node.js, Express.js, MongoDB"
            url="//stockeer.herokuapp.com"
        />

        <ProjectEntry
            details={[
                'Developed a progressive web application that provides up-to-date parking rates and real-time parking availability in Singapore.',
                'ParkingSlot aims to solve the daily frustrating problems of motorists in Singapore, such as long waiting time for searching parking space, uncertainty about parking rates and hassle to calculate total parking charges beforehand.',
            ]}
            githubId="Parking-Slot-Web-App"
            githubUrl="//github.com/jlgoh/Parking-Slot-Web-App"
            images={[parkingSlotOne, parkingSlotTwo, parkingSlotThree, parkingSlotFour]}
            logos={[parkingSlotLogo]}
            projectName="Parking Slot"
            techs="VueJS, ASP.NET Core, Microsoft SQL Server"
        />
    </div>
);

export default ProjectSection;
