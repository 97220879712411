import './style.css';
import AwardEntry from './AwardEntry';

const Awards = () => (
    <div id="awards">
        <h1 className="header">Honors and Awards</h1>
        <AwardEntry
            awardName="CodeIT Suisse Coding Challenge Finalist"
            date="Sep 2020"
            details={[
                'Worked in a team of 3 to solve algorithmic challenges over a time period of 30 hours.',
                'Built and deployed an Express API service to expose endpoints to admin server for evaluation of test cases',
                'Ranked in the top 25 teams in the competition.',
            ]}
        />
        <AwardEntry
            awardName="Khosla Innovation Scholarship"
            date="Sep 2019"
            details={[
                'Awarded for excellent academics and entrepreneurial acumen for an innovative pitch to a judge panel.',
                'Pitched about an InsurTech application that aims to improve Singaporeans’ experience with insurance through digitization and artificial intelligence.',
            ]}
        />
        <AwardEntry
            awardName="Dean’s List (Nanyang Business School)"
            date="Sep 2019"
            details={['Placed in the top 5% of the cohort in Nanyang Business School.']}
        />
        <AwardEntry
            awardName="Dean’s List (School of Computer Science and Engineering)"
            date="Sep 2019"
            details={[
                'Placed in the top 5% of the cohort in School of Computer Science and Engineering.',
            ]}
        />
        <AwardEntry
            awardName="Lim Choon Mong Top Junior College Science Student"
            date="Mar 2015"
            details={[
                'Top student in Science stream in first year of junior college at Anglo-Chinese Junior College.',
            ]}
        />
    </div>
);

export default Awards;
