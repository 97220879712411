export type WorkEntryProps = {
    companyName: string;
    date: string;
    details: string[];
    disclaimer?: string;
    jobRole: string;
    link?: string;
    logos: (string | undefined)[];
};

const WorkEntry = ({
    companyName,
    logos,
    jobRole,
    date,
    details,
    link = '',
    disclaimer = '',
}: WorkEntryProps) => (
    <div className="card">
        <div className="card-title">
            <h3>{companyName}</h3>
            {logos.map((logo) => (
                <img key={logo} alt="logo" className="logo" src={logo} />
            ))}
        </div>

        <div className="card-sub-title">
            <p>{jobRole}</p>
            <p>{date}</p>
        </div>

        <ul className="card-content">
            {details.map((detail) => (
                <li key={detail}>{detail}</li>
            ))}
        </ul>

        {disclaimer && <p>{disclaimer}</p>}

        {link && (
            <p>
                This project is hosted live{' '}
                <a href={link} rel="noreferrer" target="_blank">
                    here
                </a>
                .
            </p>
        )}
    </div>
);

export default WorkEntry;
