import './style.css';
import closeLogo from '../../assets/close_logo.svg';
import githubLogo from '../../assets/github_logo.svg';
import headshot from '../../assets/headshot.jpeg';
import linkedinLogo from '../../assets/linkedin_logo.svg';
import mailLogo from '../../assets/mail_logo.svg';

const Sider = () => {
    const closeSider = () => {
        const siderElement: HTMLElement | null = document.getElementById('sider');

        if (siderElement) siderElement.style.display = 'none';
    };

    return (
        <div id="sider">
            <div
                id="sider-close"
                onClick={closeSider}
                onKeyPress={closeSider}
                role="button"
                tabIndex={0}
            >
                <img alt="close" src={closeLogo} />
            </div>

            <img alt="avatar" src={headshot} />

            <h4 id="name">Oliver (Jun Le) Goh</h4>

            <div id="mail">
                <img alt="gh" src={mailLogo} />
                oliver.jlgoh@gmail.com
            </div>

            <div id="sm-links">
                <a href="//github.com/jlgoh" rel="noreferrer" target="_blank">
                    <img alt="gh" src={githubLogo} />
                </a>
                <a href="//linkedin.com/in/oliver-goh" rel="noreferrer" target="_blank">
                    <img alt="li" src={linkedinLogo} />
                </a>
            </div>

            <ul id="links">
                <li className="scroll-active" id="i-link">
                    <a href="#about">About Me</a>
                </li>
                <li id="e-link">
                    <a href="#education">Education</a>
                </li>
                <li id="w-link">
                    <a href="#work">Work Experience</a>
                </li>
                <li id="p-link">
                    <a href="#projects">Projects</a>
                </li>
                <li id="s-link">
                    <a href="#skills">Skills</a>
                </li>
                <li id="a-link">
                    <a href="#awards">Honors and Awards</a>
                </li>
            </ul>
        </div>
    );
};

export default Sider;
