import './style.css';
import EducationEntry from './EducationEntry';
import ntuLogo from '../../../assets/ntu_logo.png';

const Education = () => (
    <div id="education">
        <h1 className="header">Education</h1>

        <EducationEntry
            certName="Double Degree in Bachelor of Engineering in Computer Science and Bachelor of Business (with Specialization in Business Analytics)"
            date="Aug 2018 – Nov 2022"
            details={[
                'GPA: Double First Class Honors, 4.67/5.00 (Computer Science), 4.51/5.00 (Business)',
                'Took part in exchange programs at Korea University and University of Exeter and worked on both software engineering and business projects with people from South Korea, United Kingdom and United States of America.',
                "Named in Dean's List for both Nanyang Business School and School of Computer Science and Engineering.",
                'Attained the Khosla Innovation Scholarship, an award for students who have attained excellent academics and shown entrepreneurial acumen through an open pitch to a judge panel about a business idea that can impact the lives of Singaporeans significantly.',
            ]}
            logos={[ntuLogo]}
            schoolName="Nanyang Technological University"
        />
    </div>
);

export default Education;
